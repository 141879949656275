@import "nlib/config";

.activityStats {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding-top: $contentDefaultIndent * 2;
  .emptyState {
    display: flex;
    flex: auto;
    margin-top: $contentDefaultIndent * 1.5;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
  .headers {
    display: flex;
    align-items: center;
    padding: 0 $contentDefaultIndent * 1.5;
    &[disabled] {
      visibility: hidden;
    }
    .header {
      display: flex;
      flex: 1;
      width: 1px;
      overflow: hidden;
      font-size: 0.75rem;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      > div {
        margin-right: $contentDefaultIndent;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:first-child {
        flex: 4;
        width: 4px;
      }
      &.single {
        justify-content: flex-end;
        margin-right: 1.5rem;
      }
    }
  }
}
