@import "nlib/config";

.statsItem {
  + .statsItem {
    margin-top: $contentDefaultIndent * 2;
  }
  .blockItem {
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(to right, $uiBorderColor, $uiBorderColor 50%, transparent 50%);
    background-repeat: repeat-x;
    background-position: 0 calc(100% - 6px);
    background-size: 6px 1px;
    + .blockItem {
      margin-top: $contentDefaultIndent / 2;
    }
    .text {
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        padding-right: 0.25rem;
        background-color: $uiWhiteColor;
      }
      svg {
        color: $uiMediumDarkColor;
        font-size: 1rem;
        vertical-align: -0.25em;
      }
    }
    .count {
      margin-left: auto;
      padding-left: 0.25rem;
      background-color: $uiWhiteColor;
      strong + span,
      span + span {
        margin-left: 0.3em;
        color: $uiMediumDarkColor;
      }
    }
    &.userStats {
      margin-left: $contentDefaultIndent;
      .text {
        font-weight: normal;
      }
    }
  }
}
