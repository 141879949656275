@import "nlib/config";

.mobileHeader {
  .moreFilters {
    min-width: 3rem;
    border-color: $uiBorderColor;
    svg {
      font-size: 1.125rem;
    }
  }
}

.filtersModal {
  .list {
    .listItem {
      display: flex;
      align-items: center;
      min-height: 3.75rem;
      border-bottom: 0.125rem solid $uiBorderColor;
      &:first-child {
        border-top: 0.125rem solid $uiBorderColor;
      }
    }
  }
  .datePicker {
    width: 100%;
  }
  .select {
    width: 100%;
    .selectToggleButton {
      display: flex;
      align-items: center;
      svg {
        min-width: 1.125rem;
        color: $uiNeutralColor;
        font-size: 1.125rem;
      }
      .title {
        padding-right: $contentDefaultIndent;
        padding-left: $contentDefaultIndent;
        font-size: 1rem;
        font-weight: 500;
      }
      .label {
        margin-left: auto;
        overflow: hidden;
        color: $uiNeutralColor;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.hasSelected {
          color: $uiPrimaryColor;
          font-weight: 500;
        }
      }
    }
  }
  .buttons {
    margin-top: $contentDefaultIndent * 2;
    .button {
      width: 100%;
      svg {
        margin-right: $contentDefaultIndent;
        font-size: 1rem;
      }
      + .button {
        margin-top: $contentDefaultIndent * 2;
      }
    }
  }
}
