@import "nlib/config";

.filters {
  display: flex;
  z-index: 4;
  justify-content: flex-end;
  margin-top: $contentDefaultIndent * 2;
  margin-bottom: $contentDefaultIndent * 2;
  background-color: $uiBackgroundColor;
  &.active {
    position: relative;
    position: sticky;
    top: 0;
  }
  .search {
    flex: 1;
    max-width: 370px;
    margin-right: $contentDefaultIndent;
    input {
      + div svg {
        transform: scaleX(-1);
      }
    }
  }
  .resetButton {
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
  .date {
    flex: 1;
    max-width: 290px;
  }
  .type {
    flex: 1;
    max-width: 250px;
  }
  .date,
  .type {
    margin-right: $contentDefaultIndent;
  }
}

body[data-mobile] {
  .filters {
    margin: 0 $contentDefaultIndent / -2;
    padding: $contentDefaultIndent 0;
    > div {
      flex-wrap: wrap;
      justify-content: center;
    }
    .date,
    .type {
      flex: 1;
      max-width: 240px;
      margin: $contentDefaultIndent / 2;
    }
    .date {
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0 $contentDefaultIndent;
      border: 2px solid $uiBorderColor;
      border-radius: $uiBorderRadius;
      background-color: $uiWhiteColor;
      &.active {
        border-color: $uiPrimaryColor;
      }
      .datePicker {
        color: $uiFontColor;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
}
