.activityPage {
  .tabs {
    svg {
      font-size: 1rem;
    }
  }
  .pageHeader {
    margin-bottom: 0;
  }
}
