@import "nlib/config";

.monthStats {
  margin-top: $contentDefaultIndent * 2;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  .header {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent * 1.5;
    padding-left: $contentDefaultIndent;
    font-size: 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
    .month {
      display: flex;
      flex: 4;
      align-items: center;
      width: 4px;
      font-weight: 500;
      .icon {
        margin-right: $contentDefaultIndent;
        color: $uiDarkColor;
        font-size: 1rem;
      }
    }
    .count {
      display: flex;
      flex: 1;
      align-items: center;
      width: 1px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        border: 1px solid $uiBorderColor;
        border-radius: 50%;
        color: $uiMediumDarkColor;
        font-size: 16px;
      }
      .value {
        width: 80px;
        font-size: 14px;
        strong + span {
          margin-left: 0.3em;
          color: $uiMediumDarkColor;
        }
      }
      &.single {
        justify-content: flex-end;
        text-align: right;
        .value {
          span {
            margin-right: 1.5rem;
          }
        }
      }
      .sign {
        margin: 0 auto;
      }
    }
  }
  .content {
    display: flex;
    padding: 0 $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5;
    .block {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 1px;
      padding: $contentDefaultIndent * 1.5;
      border-radius: $uiBorderRadius * 2;
      background-color: $uiBackgroundColor;
      + .block {
        margin-left: $contentDefaultIndent * 1.5;
      }
      .blockHeader {
        display: flex;
        align-items: center;
        padding-bottom: $contentDefaultIndent * 1.5;
        .label {
          font-weight: 500;
        }
        .value {
          display: flex;
          align-items: center;
          height: 2rem;
          margin-left: auto;
          padding: 0 0.75em;
          border-radius: $uiBorderRadius;
          background-color: $uiWhiteColor;
          font-size: 14px;
        }
      }
      .blockContent {
        flex: auto;
        padding: $contentDefaultIndent * 1.5;
        border-radius: $uiBorderRadius;
        background-color: $uiWhiteColor;
        .title {
          display: flex;
          align-items: center;
          margin-top: $contentDefaultIndent;
          margin-bottom: $contentDefaultIndent / 2;
          color: $uiMediumDarkColor;
          font-size: 0.75rem;
          .icon {
            margin-left: 0.3em;
          }
        }
      }
    }
  }
}
