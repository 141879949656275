@import "nlib/config";

.activityFeed {
  display: flex;
  flex: auto;
  flex-direction: column;
  .preloader {
    background-color: rgba($uiWhiteColor, 0.5);
  }
  .container {
    .section {
      .title {
        margin-bottom: $contentDefaultIndent;
        color: $uiNeutralColor;
        font-size: 0.75rem;
      }
    }
    + .container {
      margin-top: $contentDefaultIndent * 2;
    }
  }
  .showMore {
    margin: $contentDefaultIndent * 2 auto 0;
    cursor: pointer;
    button {
      padding: 0 $contentDefaultIndent * 4;
    }
  }
  .emptyState {
    display: flex;
    flex: auto;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
}

[data-mobile] .activityPage {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
  }

  @media (max-width: $viewportSizeLarge) { // 992px
  }

  @media (max-width: $viewportSizeMedium) { // 768px
    .container {
      margin-bottom: $contentDefaultIndent * 2;
      padding: $contentDefaultIndent $contentDefaultIndent * 2;
      border-radius: $contentDefaultIndent;
      background-color: $uiWhiteColor;
    }
  }

  @media (max-width: $viewportSizeSmall) { // 576px
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
  }
}
